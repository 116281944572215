import React, { useContext }  from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import Home from "./Home"
import AboutMe from "./AboutMe";
import ContactMe from "./ContactMe";
import Projects from "./Projects";
import ProjectPage from "./ProjectPage";

const Routes = () => {
   
    
    return(
      <Switch>
        <Route exact path="/">
            <Home/> 
        </Route>

        <Route exact path="/about">
            <AboutMe/>
        </Route>

        <Route exact path="/contact">
            <ContactMe/>
        </Route>

        <Route exact path="/projects">
            <Projects/>
        </Route>

        <Route exact path="/projects/:projectname">
            <ProjectPage/>
        </Route>

      </Switch>
    )
}

export default Routes;