import React from "react";
import './ContactMe.css'

const ContactMe = () =>{

    return(
        <>
        <h3>CONTACT ME</h3>
        <div id="contact-wrapper">
            <div id="contact-me-text-wrapper">
                <p id="contactmetext">
                I'm on the lookout for exciting projects and opportunities. 
                </p>
                <p id="typing-text">Let's C<span className="typewriter"></span></p>
            </div>

            <div id="contact-details-wrapper">
                <a href="mailto:naotakahashi93@gmail.com"><i className="fa-regular fa-envelope"></i>&nbsp;naotakahashi93@gmail.com</a>
                <a href="https://github.com/naotakahashi93" target="_blank"><i className="fa-brands fa-github"></i>&nbsp;GitHub</a>
                <a href="https://www.linkedin.com/in/naotakahashi/" target="_blank"><i className="fa-brands fa-linkedin"></i>&nbsp;LinkedIn</a>
            </div>
        </div>

        </>
    )
}

export default ContactMe;