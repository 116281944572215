import React from "react";
import './Projects.css';
import { Link} from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';

const Projects = () =>{

    const projectsImgArr = ["fastkitchen", "pathfinder", "jobly", "warbler"]
    const projDescrptionObj = {
        fastkitchen : {
            title: "Fast Kitchen",
            URL: "https://fastkitchen.herokuapp.com/",
            description: "FastKitchen is a recipe generator application that makes cooking easy by generating recipes using the ingredients they have on hand."
        },
        pathfinder : {
            title: "PathfinderAI",
            URL: "https://pathfinderai.surge.sh/",
            description: "PathfinderAI is a ChatGPT powered travel itinerary builder that generates a personalized travel itinerary based on their preferences."
        },
        jobly : {
            title: "Jobly",
            URL: "https://reactjobly.surge.sh/",
            description: "Jobly is a mock job posting website that lets users (job seekers) search, filter and apply for job openings."
        },
        warbler : {
            title: "Warbler",
            URL: "https://warbler-m7s1.onrender.com/",
            description: "Warbler is a Twitter clone web application. The app allows users to create accounts, post/like/delete tweets and follow other users."
        },}

    return(
        <>
        <h3 id="my-projects-title">PROJECTS</h3>
        <div id="portfolio-display-wrapper">
            {projectsImgArr.map((proj)=> (
                <div className="project-landing-wrapper"  key={uuidv4()}>
                    <img className="project-landing-img" src={require(`./images/${proj}Landing.png`)}></img>
                    <a href={projDescrptionObj[proj]["URL"]} className="project-tile" target="_blank">
                        {projDescrptionObj[proj]["title"]}
                    </a>
                    <p className="project-description">
                        {projDescrptionObj[proj]["description"]}   
                        <br></br>
                        <Link id="learnmore-btn" to={`/projects/${proj}`}>Learn More<i className="fa-solid fa-angles-right"></i></Link>
                    </p>
                 
                </div>
            ))}
        </div>
        </>
    )
}

export default Projects;