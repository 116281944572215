import React, { useState }  from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css"
import logo from "./images/nt_logo.png"

const NavBar = () =>{

    const [mobileMenu, setMobileMenu] = useState(false);
    const [mobileXicon, setmobileXicon] = useState(false);

    const toggleMenu = () => {
        setMobileMenu(!mobileMenu);
        setmobileXicon(!mobileXicon)
      };

    const toggleMobileBtn = () =>{
        setmobileXicon(!mobileXicon)
    }

  return (
    <div id="bardiv">
        <div id="homelogo" className={mobileMenu ? 'open' : ''}>
            <NavLink to="/" id="logoNavLinkWrapper"><img src={logo} alt="homelogo"></img></NavLink>
        </div>
        <div id="menudiv" className={mobileMenu ? 'open' : 'close'}>
            <NavLink to="/about" onClick={toggleMenu} > 
                ABOUT ME
            </NavLink>
            <NavLink to="/projects" onClick={toggleMenu} > 
                PROJECTS
            </NavLink>
            <NavLink to="/contact" onClick={toggleMenu}> 
                CONTACT
            </NavLink>
            <a href="../NaoTakahashiResume.pdf" target="_blank"><i className="fa-regular fa-file-lines"></i></a>
            <a href="https://github.com/naotakahashi93" target="_blank"><i className="fa-brands fa-github"></i></a>
                <a href="https://www.linkedin.com/in/naotakahashi/" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
        </div> 

        <div className={`mobile-menu ${mobileMenu ? 'open' : ''}`} onClick={toggleMenu} >
            <div className="hamburger-icon">
                <div className={`xbtn ${mobileXicon ? 'active' : 'not-active'}`} onClick={toggleMobileBtn}>
                    <span className="burgerlines"></span>
                    <span className="burgerlines"></span>
                    <span className="burgerlines"></span>
                </div>
            </div>
        </div>
        <div className={`overlay ${mobileMenu ? 'open' : ''}`} onClick={toggleMenu}></div>
    </div>
  );
}

export default NavBar;