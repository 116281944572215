import React, {useState, useEffect} from "react";
import { useParams, useHistory } from "react-router-dom";
import './ProjectPage.css'
import mockup from "./images/computerMockUp.png"


const ProjectPage = () =>{

    const {projectname} = useParams()
    const history = useHistory();

    const [currentSrc, setCurrentSrc] = useState("demo.gif");
    const gifs = ["demo.gif", "demo2.gif"]; // Add more GIFs if needed

    const techStackArr = ["Frontend", "Backend", "Database", "API", "Images"]

    useEffect(() => {
        const interval = setInterval(() => {
          const nextIndex = (gifs.indexOf(currentSrc) + 1) % gifs.length;
          setCurrentSrc(gifs[nextIndex]);
        }, 20000); 
    
        return () => {
          clearInterval(interval);
        };
      }, [currentSrc]);


    const projDescrptionObj = {
        fastkitchen : {
            title: "Fast Kitchen",
            URL: "https://fastkitchen.herokuapp.com/",
            intro: "FastKitchen is a user-friendly website designed to simplify homemade cooking, catering to busy individuals seeking easy and hassle-free meal preparation. The app is a valuable tool for those with demanding schedules who often find themselves in need of recipe inspiration using the ingredients available in their kitchen.",
            featuresbold: ["Effortless Cooking:", "Quick Start:", "Ingredient Checklist:", "Instant Recipe Suggestions:", "Favorites Management:", "Personalization:"],
            featuresdesc: {
                "Effortless Cooking:": " FastKitchen streamlines the cooking process, making it straightforward to create meals with the ingredients at hand",
                "Quick Start:": " New users can easily sign up, while existing users can log in seamlessly. Guests are also welcome to explore the app without the need for an account.",
                "Ingredient Checklist:": " Users can compile their ingredient list by selecting items from various categories or using the convenient search bar.",
                "Instant Recipe Suggestions:": " Clicking 'Get Recipes' generates a curated list of recipe ideas based on the selected ingredients, powered by the Spoonacular API",
                "Favorites Management:": " Registered users can mark favorite ingredients for easy access to frequently used items.",
                "Personalization:": " Logged-in users enjoy the ability to manage and tailor their favorite ingredients, enhancing the meal planning experience."
            },
            techstack: {
                "Frontend": ["JavaScript", "jQuery", "HTML", "CSS"],
                "Backend": ["Python", "Flask", "SQL", "SQLAlchemy"], 
                "Database": ["PostgreSQL", "SQLAlchemy"],
                "API": ["Spoonacular API"], 
                "Images": ["FontAwesome", "Generated from Leonardo.AI"]
            },
            usage : {
                1: " New users can sign up for an account, and existing users can log in to access personalized features.",
                2: " Users can select ingredients from categories or search for specific items, building their ingredient list.",
                3: ' Click "Get Recipes" to receive tailored recipe suggestions based on the selected ingredients.',
                4: " Follow the detailed recipe instructions to prepare delicious homemade meals.",
                5: " Logged-in users can manage their favorite ingredients and quickly incorporate them into their ingredient checklist.",
            }
        },

        pathfinder : {
            title: "PathfinderAI",
            URL: "https://pathfinderai.surge.sh/",
            intro: "The aim of this project is to provide users with a custom and interactive travel experience. By using the chatGPT API and the HEREMaps API, the application allows users to input their desired travel location, travel dates, and additional preferences such as travel companions and interests. Based on this information, the system will generate personalized travel itineraries and plot the suggested locations on a map. The objective is to provide users with a convenient and efficient tool to plan their travel experiences with ease.",
            featuresbold: ["ChatGPT Integration:", "HEREMapsAPI Integration:", "Flexible Input Options:", "Pre-made Images:", "Itinerary Saving:"],
            featuresdesc: {
                "ChatGPT Integration:": " Utilizes the power of ChatGPT, and provides a user-friendly interface where users can easily input their travel preferences to generate a custom itinerary.",
                "HEREMapsAPI Integration:": " Integrates with the HEREMapsAPI to access location data, providing a map visual that displays various attractions, restaurants and tourist sites.",
                "Flexible Input Options:": " Users have the freedom to provide various inputs such as the destination, travel dates, travel companions and interests.",
                "Pre-made Images:": " Incorporates a collection of pre-made images that are associated with specific destinations curated and generated using Leonardo.AI.",
                "Itinerary Saving:": " Provides the option for users to sign up and gain the ability to save their generated itineraries.",
            },
            techstack:{
            "Frontend":["JavaScript", "React", "HTML", "CSS"],
            "Backend": ["Python", "Flask", "Redis"], 
            "Database":["PostgreSQL", "SQLAlchemy"],
            "API": ["OpenAI API (Model: text-davinci-003)", "HEREMaps API"],
            "Images": ["Generated from Leonardo.AI"]
            },
            usage : {
                1: " Enter your desired travel destination and optionally dates and other interests.",
                2: " Once you've inputted/selected all the necessary inputs, the application will generate a detailed travel itinerary for your trip.",
                3: " Explore the generated itinerary and the locations on the map.",
                4: " Option to save the itinerary for future reference with sign up.",
                5: " There are sample itineraries at the bottom of the landing page of some popular travel destinations!"
            }
        },

        jobly : {
            title: "Jobly",
            URL: "https://reactjobly.surge.sh/",
            intro: "Jobly is a mock job posting website that enables users (job seekers) to search and apply for job openings. The platform utilizes Node.js as the backend and React for the front end. Job names and company names were sourced from a custom API provided by the school.",
            featuresbold: ["User Registration and Login:", "Job and Company Filtering:", "Applied Jobs Tracking:", "Company-Centric Job Application:"],
            featuresdesc: {
                "User Registration and Login:": " Users can create accounts and log in using JWT authentication.",
                "Job and Company Filtering:": " Users can search and filter through a list of jobs and companies using various criteria such as search terms, maximum employees, or minimum salary.",
                "Applied Jobs Tracking:": " Applied jobs are saved in an SQL Database and are displayed on the user profile page.",
                "Company-Centric Job Application:": " Users can apply for jobs through the 'Companies' tab, where job openings are listed by company. Clicking on a specific job reveals an 'Apply' button.",
            },
            techstack:{
                "Frontend": ["JavaScript", "React", "HTML", "CSS"],
                "Backend": ["Node.js"],
                "Database": ["PostgreSQL", "SQL"],
                "API": ["Local"],
                "Images": ["Canva"],
            },
            usage : {
                1: " Signup or login (demo login - username: test15, password: test15).",
                2: ' Browse through the "Jobs" or "Companies" tab, optionally applying filters.',
                3: ' Click on "Apply" for desired jobs.',
                4: ' View applied jobs on the "Profile" tab, with the option to edit details.',
                5: " Apply for jobs by company by clicking on a company and selecting specific job openings."
            }
        },
        warbler : {
            title: "Warbler",
            URL: "https://warbler-m7s1.onrender.com/",
            intro: "Warbler is a Twitter clone web application developed as part of a school project. It is built using Python and Flask for the backend, and Jinja templates with HTML for the frontend. The app allows users to create accounts, post, like, and delete tweets, as well as follow other users.",
            featuresbold: ["User Authentication:", "Tweeting:", "Following:", "Feeds:", "Profiles:"],
            featuresdesc: {
                "User Authentication:": " Securely sign up for new accounts, log in, and log out.",
                "Tweeting:": " Authenticated users can compose and publish tweets to share with followers.",
                "Following:": " Users can follow and unfollow other users to stay updated with their tweets.",
                "Feeds:": " Upon login, users are presented with a personalized feed displaying tweets from users they follow.",
                "Profiles:": " Each user has a profile page showcasing their tweets, followers, and followed users."
            },
            techstack:{
                "Frontend": ["HTML", "CSS", "Jinja"],
                "Backend": ["Python", "Flask"],
                "Database": ["PostgreSQL", "SQL"],
                "API": ["Local"],
                "Images": ["Generated from Leonardo.AI"]
            },
            usage : {
                1: " Register for a new account or log in with existing credentials.",
                2: " Compose tweets from the home page and post them to your followers' feeds.",
                3: " Explore/like tweets from all users on your feed.",
                4: " Follow and unfollow other users to stay updated with their tweets.",
                5: " View your profile and see your tweets, followers, and followed users."
            }
        },}

    const handleBack = (e) =>{
        e.preventDefault();
        history.push("/projects")
      }
      
    return(
        <>
        <h2 id="projectpage-title">
            {projDescrptionObj[projectname]["title"]}
        </h2>
        <a href={projDescrptionObj[projectname]["URL"] }id="projectpage-url" target="_blank">
        <i class="fa-solid fa-link"></i>&nbsp;{projDescrptionObj[projectname]["URL"]}
        </a>
        <p id="projectpage-intro">
            {projDescrptionObj[projectname]["intro"]}
        </p>
        <div id="mock-up-features-wrapper">
            <div id="mock-up-wrapper">
                <img src={mockup} id="computer-mock-up"></img>
                <img src={require(`./images/${projectname}-${currentSrc}`)} id="demo-gif" alt="demo-gif"></img>
                <small id="mockup-credit">Image by d3images on Freepik</small>
            </div>

            <div id="features">
                <p id="key-features-title">Key Features</p>
                <ul>
                {projDescrptionObj[projectname]["featuresbold"].map((bold) => 
                    <li>
                    <span id="feature-list-bold">{bold}</span> 
                    {projDescrptionObj[projectname]["featuresdesc"][`${bold}`]}
                    </li>
                    )}
                </ul>
            </div>
        </div>

        <div id="tech-stack-wrapper">
            {techStackArr.map( (stack) =>(
                    <div id="tech-stack">
                        <p id="stack-title"> {stack}</p>
                        <p id="stack-desc"> {projDescrptionObj[projectname]["techstack"][`${stack}`].map( (each) => <div id="each-lang"> {each}</div>)} </p>
                    </div>) 
            )}
        </div>

        <div id="homepage-sc-wrapper">
            <img id="homepage-sc-img" src={require(`./images/${projectname}-homepage.png`)}></img>
        </div>

        <div id="usage-wrapper">
            <h3 id="usage-title">Usage</h3>
           {Object.keys(projDescrptionObj[projectname]["usage"]).map(
                (step) => (
                    <div id="usage-step-wrapper">
                      <div id="usage-num-description">
                        <p id="step-num">{step}</p>
                        <p id="step-desc">{projDescrptionObj[projectname]["usage"][`${step}`]}</p>
                      </div>
                      <div id="usage-img">
                        <img src={require(`./images/${projectname}-usage-${step}.gif`)}></img>
                      </div>
                    </div>)
                    )}
        </div>

        <div onClick={handleBack} id="back-to-projects"><i class="fa-solid fa-angles-left"></i>Back to Projects</div>
        </>
    )
}

export default ProjectPage;