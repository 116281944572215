import React from "react";
import './Home.css'
import AboutMe from "./AboutMe";
import Projects from "./Projects";
import ContactMe from "./ContactMe";

const Home = () =>{

    return(
        
        <div id="home-wrapper">
            <h1> HI, I'M NAO. </h1>
            <h2 id="home-h2"> FULL-STACK WEB DEVELOPER</h2>
            <div id="home-icons-wrapper">
                <div id="home-resume-div">
                    <a href="../NaoTakahashiResume.pdf" target="_blank"><i className="fa-regular fa-file-lines"></i><span className="speech-span">Resume</span></a>
                </div>
                <div id="home-github-div">
                    <a href="https://github.com/naotakahashi93" target="_blank"><i className="fa-brands fa-github"></i><span className="speech-span">GitHub</span></a>
                </div>
                <div id="home-linkedin-div">
                    <a href="https://www.linkedin.com/in/naotakahashi/" target="_blank"><i className="fa-brands fa-linkedin"></i><span className="speech-span">LinkedIn</span></a>
                </div>
            </div>
            <AboutMe/>
            <Projects/>
            <ContactMe/>
        </div>
        
    )
}

export default Home;