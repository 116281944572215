import NavBar from "./NavBar";
import Routes from "./Routes";

function App() {
  return (
    <div className="App">
        <NavBar/>
        <div style={{marginTop:"100px"}}>
          <Routes/>
        </div>
    </div>
  );
}

export default App;
