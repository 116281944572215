import React, {useState} from "react";
import './AboutMe.css';
import myphoto from "./images/NaoPhoto.jpg"
import { v4 as uuidv4 } from 'uuid';

const AboutMe = () =>{

    const [filter, setFilter] = useState("all")
    
    const deviconObj = {
        html: {
            name: "HTML", 
            iconsrc: "html5/html5-original",
            type: "frontend"
        },
        css: {
            name: "CSS", 
            iconsrc: "css3/css3-original",
            type: "frontend"
        },
        js: {
            name: "JavaScript", 
            iconsrc:"javascript/javascript-original",
            type: "frontend"
        },
        react: {
            name: "React", 
            iconsrc: "react/react-original", 
            type: "frontend"
        },
        jquery: {
            name: "jQuery", 
            iconsrc: "jquery/jquery-original",
            type: "frontend"
        },
        python: {
            name: "Python", 
            iconsrc: "python/python-original",
            type: "backend"
        },
        flask: {
            name: "Flask", 
            iconsrc: "flask/flask-original",
            type: "backend"
        },
        nodejs: {
            name: "Node.js", 
            iconsrc: "nodejs/nodejs-original",
            type: "backend"
        },
        postgresql: {
            name: "PostgreSQL", 
            iconsrc: "postgresql/postgresql-plain",
            type: "database"
        },
        sqla: {
            name: "SQLAlchemy", 
            iconsrc: "sqlalchemy/sqlalchemy-original",
            type: "database"
        },
        jasmine: {
            name: "Jasmine", 
            iconsrc: "jasmine/jasmine-plain",
            type: "testing"
        },
        jest: {
            name: "Jest", 
            iconsrc: "jest/jest-plain",
            type: "testing"
        },
    }

    const filterDisplay = (filt) =>{
        setFilter(filt)
    }
    

    return(
        <>
        <h3>ABOUT ME</h3>
        <div id="photo-and-intro">
            <div id="photo-wrapper">
                <img id="myphoto" src={myphoto}></img>
            </div>
            <p id="aboutmetext">
                Originally from Tokyo Japan, currently based out of Vancouver BC. 
                <br></br>
                I'm a full-stack web developer who appreciates the balance between practicality and aesthetics.
                I enjoy crafting user interfaces that are intuitive and visually appealing while also ensuring solid functionality behind the scenes.
                I strive to build things that are user-friendly, accessible and beneficial to everyone.
                <br></br>
                In my spare time I love traveling, doing DIY projects, and enjoying time with my dog!
            </p>
        </div>

        <h3 id="skills-title">SKILLS</h3>

        <div id="lang-filtering-nav" > 
            <p id="lang-filter-all" className={filter === 'all' ? 'lang-filter-active' : ''} onClick={()=>filterDisplay("all")}>ALL</p> 
            <p id="lang-filter-front" className={filter === 'frontend' ? 'lang-filter-active' : ''} onClick={()=>filterDisplay("frontend")}>FRONTEND</p> 
            <p id="lang-filter-back" className={filter === 'backend' ? 'lang-filter-active' : ''} onClick={()=>filterDisplay("backend")}>BACKEND</p>  
            <p id="lang-filter-db" className={filter === 'database' ? 'lang-filter-active' : ''} onClick={()=>filterDisplay("database")}>DATABASE</p> 
            <p id="lang-filter-test" className={filter === 'testing' ? 'lang-filter-active' : ''} onClick={()=>filterDisplay("testing")}>TESTING</p> 
        </div>

        { filter === "all" 
        ?
           <div id="all-icons-wrapper">
        {Object.keys(deviconObj).map( 
                (lang) => 
                <div key={uuidv4()}className="lang-wrapper">
                    <div className="deviconWrapper">
                    <img className="devicon" src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${deviconObj[lang]["iconsrc"]}.svg`} />
                    </div>
                    <p id="lang-name">{deviconObj[lang]["name"]}</p>
                </div>
            )}
        </div>
        :
        <div id="all-icons-wrapper">
            {Object.keys(deviconObj).filter(key => deviconObj[key].type === filter).map(
                (lang) => 
                <div key={uuidv4()}className="lang-wrapper">
                    <div className="deviconWrapper">
                        <img className="devicon" src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${deviconObj[lang]["iconsrc"]}.svg`} />
                    </div>
                    <p id="lang-name">{deviconObj[lang]["name"]}</p>
                </div> 
            )}
        </div>
        }
      

  
        </>
    )
}

export default AboutMe;